import {
  SplitSdk,
  SplitFactory,
  SplitClient,
} from '@splitsoftware/splitio-react';
import React from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
/* eslint-disable no-console */

export default class SplitReactSDK {
  static key = 'anonymous';

  static _factory;

  static initialized = false;

  static config = {
    core: {
      authorizationKey: process.env.REACT_APP_SPLIT_AUTHORIZATION_KEY,
      key: this.key,
    },
    impressionListener: {
      logImpression(data) {
        datadogRum.addFeatureFlagEvaluation(
          data.impression.feature,
          data.impression.treatment
        );
      },
    },
  };

  static get client() {
    return this._factory.client(this.key);
  }

  static init({ debug = false, streamingEnabled = false } = {}) {
    this.config = { ...this.config, debug, streamingEnabled };
    this._factory = SplitSdk(this.config);
    this.initialized = true;
  }

  static setUserId(userId) {
    this.key = userId;
  }

  static cache = {};

  static cacheExpiryTime = 2 * 60 * 1000;

  static maxRetries = 3;

  static getCachedFeatureFlagValue(featureKey) {
    const cachedItem = localStorage.getItem(`featureFlag_${featureKey}`);
    if (cachedItem) {
      const cachedValue = JSON.parse(cachedItem);
      const currentTime = Date.now();
      if (cachedValue.expiry > currentTime) {
        return cachedValue.value;
      }
      localStorage.removeItem(`featureFlag_${featureKey}`);
    }
    return null;
  }

  static async getFeatureFlagValue(featureKey) {
    if (!this.initialized) {
      this.init();
    }

    const cachedValue = this.getCachedFeatureFlagValue(featureKey);

    if (cachedValue !== null) {
      return cachedValue === 'on';
    }
    return this.updateFeatureFlagValue(featureKey);
  }

  static async updateFeatureFlagValue(featureKey, retries = 0) {
    const client = this.client;

    try {
      const treatment = client.getTreatment(featureKey);

      if (treatment === 'control') {
        if (retries < this.maxRetries) {
          return this.updateFeatureFlagValue(featureKey, retries + 1);
        }
        return null;
      }
      const cacheItem = {
        value: treatment,
        expiry: Date.now() + this.cacheExpiryTime,
      };
      localStorage.setItem(
        `featureFlag_${featureKey}`,
        JSON.stringify(cacheItem)
      );

      return treatment === 'on';
    } catch (error) {
      return null;
    }
  }
}

export const SplitReact = ({ children }) => {
  const user = useSelector(state => get(state, 'userDetails.data', {}));
  const userId = get(user, 'ID', 'anonymous');
  SplitReactSDK.setUserId(userId);

  const attributes = {
    platform: 'sn-frontend',
    email: get(user, 'email', ''),
    role: get(user, 'userRoleRelations.data[0].role.name', ''),
    isStaff: get(user, 'userRoleRelations.data[0].role.isStaff', false),
    centerIDs: (get(user, 'userAccessControls.data', []) || [])
      .map(c => get(c, 'centre.ID'))
      .filter(id => id)
      .map(d => d.toString()),
  };

  return (
    <SplitFactory
      factory={SplitReactSDK._factory}
      updateOnSdkReady
      updateOnSdkTimedout
      updateOnSdkUpdate
    >
      <SplitClient splitKey={userId} attributes={attributes}>
        {children}
      </SplitClient>
    </SplitFactory>
  );
};
